import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import { cloneDeep, find, findIndex, hasIn, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '../../auth/authOperations';
import { CREATE_DELEGATES } from '../../auth/permissions';
import SurveyPageCard from './surveyPageCard.component';
import LoadingOverlay from '../../layout/loadingOverlay.component';

import { getCurrentPageTabs } from '@survey/common/dist/actions/pages.actions';
import { addDelegate, removeDelegate, resendDelegate, setDelegates } from '@survey/common/dist/actions/delegates.actions';
import { getSurveyAMAMScore, getSurveyScore, saveSurvey, setSurvey, submitSurvey, SUBMIT_SURVEY_SUCCESS } from '@survey/common/dist/actions/surveys.actions';
import { hasDelegatePermission } from '@survey/common/dist/utilities/delegates';
import { calculatePageStatus } from '@survey/common/dist/utilities/surveyUtils';
import CustomTabs from '@survey/common/dist/components/form-controls/CustomTabs';
import { createSurveyStateForEntity } from '@survey/common/dist/utilities/createSurveyState';
import DelegateModal from '@survey/common/dist/components/delegates/delegateModal.component';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import { handleToastMessage } from '../../layout/layout.actions';
import { getResource } from '@survey/common/dist/utilities/getResource';

class Entity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entity: {},
      open: false,
      survey: this.props.survey,
      showConfirmDialog: false,
      isLoading: false,
      page: {},
      openChildDialog: false,
      showSubmitDialog: false,
    };

    ['viewPage', 'updateSurvey', 'updateSurveyChanges', 'handleSendDelegates', 'handleRemoveDelegate', 'handleResendDelegate', 'saveSurvey', 'completePage', 'dhiShortSubmit'].map(
      (func) => (this[func] = this[func].bind(this))
    );
  }

  static getDerivedStateFromProps(props, state) {
    const { tabs, pages, survey, surveyType, technologies, resources } = props;
    let newState = { ...state };

    if (!isEmpty(surveyType)) {
      const entity = find(survey.entities, { entityID: Number(props.match.params.entityId) });

      if (surveyType && entity && isEmpty(newState.entity)) {
        const surveyState = createSurveyStateForEntity(entity, survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies, resources);
        let pageQuestionCount = [];

        const entityPages = cloneDeep(surveyState.pages);
        const pagesWithProgress = entityPages.map((p) => {
          if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
            p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
          }

          return p;
        });

        pagesWithProgress.forEach((p) => {
          const { surveyType, tabs } = surveyState;
          const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);
          pageQuestionCount.push({ pageId: p.pageId, totalQuestions: pageStatus.totalQuestions, tabQuestionCount: pageStatus.tabQuestionCount });
        });
        newState = { ...newState, ...surveyState, entity, pageQuestionCount };
      }
    }

    return newState;
  }

  // async componentWillUnmount() {
  //   const { survey } = this.state;
  //   const { surveyType, isLoading } = this.props;

  //   if (!isEqual(survey, this.props.survey) && surveyType.surveyTemplate === 'One Page' && !isLoading) {
  //     this.saveSurvey(survey);
  //   }
  // }

  viewPage(pageID) {
    const { history, match } = this.props;

    history.push(`/surveyLanding/${match.params.id}/entity/${this.state.entity.entityID}/page/${pageID}`);
  }

  completePage(page) {
    let survey = cloneDeep(this.state.survey);

    survey.pageProgress[`${this.state.entity.entityID}-${page.pageId}`] = {
      percentComplete: 100,
      completed: true,
    };

    this.saveSurvey(survey);
  }

  async dhiShortSubmit() {
    let survey = cloneDeep(this.state.survey);

    this.saveSurvey(survey).then(() => {
      console.log('showing submit dialog!');
      this.setState({ showSubmitDialog: true });
    });
  }

  async onSubmit() {
    const { surveyType, resources } = this.props;
    let survey = cloneDeep(this.state.survey);
    /* Hide the dialog */
    this.setState({ showSubmitDialog: false });
    let allowSubmit = ['Not Started', 'In Progress'];
    if(!allowSubmit.includes(survey.status)){
      this.props.handleToastMessage(getResource(resources, survey.language, 'Label', 'Failed to submit survey!'), true);
      return;
    }

    if (surveyType.scoringType === 'DHIShort') {
      /* Save the survey then submit if DHI Short */
      this.saveSurvey(survey).then(async () => {
        const response = await this.props.submitSurvey(this.props.survey.surveyGuid);
        if (response.type === SUBMIT_SURVEY_SUCCESS) {
          this.props.handleToastMessage(getResource(resources, survey.language, 'Message', 'Survey successfully submitted.'), false);
          setTimeout(function () {
            window.location.replace('https://www.himss.org/');
          }, 3000); //I think this redirects them home, and when they land home, it runs through welcome component, which defaults to step 0 which shows the screen we see.
        } else {
          this.props.handleToastMessage(getResource(resources, survey.language, 'Message', 'Failed to submit survey!'), true);
        }
      });
    } else {
      /* Submit the survey */
      const response = await this.props.submitSurvey(this.props.survey.surveyGuid);
      if (response.type === SUBMIT_SURVEY_SUCCESS) {
        this.props.handleToastMessage(getResource(resources, survey.language, 'Message', 'Survey successfully submitted.'), false);
        //this.props.history.push('/'); //I think this redirects them home, and when they land home, it runs through welcome component, which defaults to step 0 which shows the screen we see.
      } else {
        this.props.handleToastMessage(getResource(resources, survey.language, 'Message', 'Failed to submit survey!'), true);
      }
    }
  }

  updateSurvey(surveyID, entityID, data) {
    const { surveyType } = this.props;
    let entities = [...this.state.survey.entities];
    const idx = findIndex(this.state.survey.entities, { entityID });
    // Exit if we can't find the entity to update.

    if (idx === -1) return;

    entities[idx] = { ...entities[idx], data };
    let survey = cloneDeep(this.state.survey);
    survey.entities = entities;

    /* Set the status to In Progress if it hasn't been already */
    if (survey.status !== 'In Progress') {
      survey.status = 'In Progress';
    }
    if (surveyType.surveyTemplate === 'One Page') {
      this.setState({ survey, entity: entities[idx] });
    } else {
      this.saveSurvey(survey);
    }
  }
  async updateSurveyChanges(surveyID, entityID, changes) {
    const { surveyType } = this.props;
    const response = await this.props.saveSurveyChanges(surveyID, entityID, changes);
    if (response.type === 'SAVE_SURVEY_CHANGES_SUCCESS') {
      if (surveyType.scoringType === 'AMAM') {
        this.props.getSurveyAMAMScore(surveyID);
      } else {
        this.props.getSurveyScore(surveyID);
      }
    }
  }
  async saveSurvey(survey) {
    const { surveyType, technologies, match } = this.props;
    const { pages, entity, tabs } = this.state;
    delete survey.bsonId;
    // TODO:  We should just remove the questions and technologyQuestions entirely but right now that will delete them from the survey
    survey.questions.forEach((q) => {
      delete q.bsonId;
    });
    survey.technologyQuestions.forEach((q) => {
      delete q.bsonId;
    });

    const pageProgress = {};

    cloneDeep(pages).forEach((p) => {
      if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`] && survey.pageProgress[`${entity.entityID}-${p.pageId}`].completed) {
        p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
      } else {
        const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);

        const percentComplete = (pageStatus.totalQuestionsAnswered / pageStatus.totalQuestions) * 100;

        p.pageProgress = {
          completed: false,
          percentComplete: isNaN(percentComplete) ? 0 : Math.round(percentComplete),
        };
      }

      pageProgress[`${entity.entityID}-${p.pageId}`] = p.pageProgress;

      return p;
    });

    survey.pageProgress = { ...survey.pageProgress, ...pageProgress };
    const response = await this.props.saveSurvey(match.params.id, survey);

    if (response.type === 'SAVE_SURVEY_SUCCESS') {
      //this.props.setSurvey(survey);

      if (surveyType.scoringType === 'AMAM') {
        this.props.getSurveyAMAMScore(survey.surveyID);
      } else {
        this.props.getSurveyScore(survey.surveyID);
      }
    }
  }

  async handleSendDelegates(newDelegates, sendPlainText) {
    const { entity, survey } = this.state;
    const { addDelegate, delegates, setDelegates } = this.props;

    console.log('in handleSendDelegates in entity.component, sendPlainText: ', sendPlainText);

    const delegatesClone = cloneDeep(delegates);
    let newSurveyDelegates = {};
    this.setState({ isLoading: true, open: false });
    for (const [index, d] of newDelegates.entries()) {
      const response = await addDelegate(survey.surveyID, d, sendPlainText);

      if (response.type === 'ADD_DELEGATE_SUCCESS') {
        newSurveyDelegates = response.response;
      }
    }
    await setDelegates(newSurveyDelegates);
    //this.setState({ entity: {}, isLoading: false }, () => this.props.handleDialogClose());
    //this.setState({ entity: {}, isLoading: false }) ;  //removed isLoading: false due to hacky fix below
    this.props.handleDialogClose();

    //hacky fix until we can get setdelegates() to update the delegate modal that shows on entity component (to show categories delegated)
    //window.location.reload();
  }

  async handleRemoveDelegate(delegate) {
    const { survey } = this.state;
    const { removeDelegate, delegates, setDelegates } = this.props;
    const delegatesClone = cloneDeep(delegates);

    //delete delegate.accessDelegated;
    //delete delegate.pageID;
    console.log('EC removing delegate: ', delegate);
    console.log('EC survey.surveyID: ', survey.surveyID);
    const response = await removeDelegate(survey.surveyID, delegate);

    if (response.type === 'REMOVE_DELEGATE_SUCCESS') {
      if (!hasIn(delegate, 'entityID')) {
        const userIndex = findIndex(delegates.users, { email: delegate.email });

        delegatesClone.users.splice(userIndex, 1);
        await setDelegates(delegatesClone);
      } else if ('pageID' in delegate && delegate.pageID !== undefined) {
        const entityIndex = findIndex(delegatesClone.entities, { entityID: delegate.entityID });
        const pageIndex = findIndex(delegatesClone.entities[entityIndex].categories, { pageID: delegate.pageID.toString() });
        const userIndex = findIndex(delegatesClone.entities[entityIndex].categories[pageIndex].users, { email: delegate.email });

        delegatesClone.entities[entityIndex].categories[pageIndex].users.splice(userIndex, 1);
      } else if ('entityID' in delegate) {
        const entityIndex = findIndex(delegatesClone.entities, { entityID: delegate.entityID });
        const userIndex = findIndex(delegatesClone.entities[entityIndex].users, { email: delegate.email });

        delegatesClone.entities[entityIndex].users.splice(userIndex, 1);
      }
    }

    await setDelegates(delegatesClone);
  }

  async handleResendDelegate(delegate, delegateModalDelegates, delegateModalSurvey, plainText) {
    const { survey, resendDelegate } = this.props;

    console.log('in handleResendDelegate in entity.component, sendPlainText: ', plainText);

    delete delegate.accessDelegates;
    const response = await resendDelegate(survey.surveyID, delegate, plainText);

    if (response.type === 'RESEND_DELEGATE_SUCCESS') {
      this.setState({ showConfirmDialog: true });
    }
  }

  getPageDelegates(pageID) {
    const { entity } = this.state;
    const { entities } = this.props.delegates;

    const delegateEntity = find(entities, { entityID: entity.entityID });

    if (delegateEntity) {
      const delegatePage = find(delegateEntity.categories, { pageID: pageID.toString() });

      return delegatePage ? delegatePage.users : [];
    }

    return [];
  }

  render() {
    const {
      answers,
      auth,
      classes,
      countriesList,
      delegates,
      //entityAmbulatories,
      //entityFreeStandingDataCenters,
      //entityHomeHealths,
      //entityHospitals,
      //entityInHospitalDataCenters,
      //entitySubAcutes,
      //entityUntetheredAmbulatories,
      productsList,
      regionsList,
      technologies,
      technologiesList,
      vendorsList,
      permissions,
      isSaving,
      regions,
      openChildDialog,
      resources,
      isInternalUser,
    } = this.props;
    const { entity, survey, surveyType, pages, tabs, open, page, pageQuestionCount, questions } = this.state;

    if (entity === undefined || surveyType === undefined || survey === undefined) {
      return <LoadingOverlay />;
    }

    let sortedPages = cloneDeep(pages);

    //if the surevy is AMAM we need to sort sections by pageId ASC
    //  if (surveyType.name === 'AMAM') {
    //    sortedPages = sortedPages.sort((a, b) => (a.pageId > b.pageId ? 1 : -1));
    //  }

    const pagesWithProgress = sortedPages.map((p) => {
      if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
        p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
      }

      return p;
    });

    const currentFilteredPageList = [];

    //{!isEmpty(entity) && !isEmpty(surveyType) && !isEmpty(survey) && (
    //console.log('entity: ', entity);
    //console.log('surveyType: ', surveyType);
    //console.log('survey: ', survey);
    //console.log('pages: ', pages);

    if (surveyType.surveyTemplate === 'One Page') {
      return (
        <div className={classes.appFrame}>
          <main className={classes.content}>
            {pages
              .filter((page) => {
                const isPermitted = hasDelegatePermission(delegates, auth.email, entity.entityID, page.pageId.toString());
                if (!isPermitted) return false;
                currentFilteredPageList.push(page);
                return true;
                //return (entity.haEntityTypeID === 6 || entity.haEntityTypeID === 8) && page.pageId !== 8 && page.pageId !== 13 && page.pageId !== 214 ? false : true;
              })
              .map((page) => {
                const currentTabs = getCurrentPageTabs(page.pageId, tabs, survey.questions, surveyType.questions, survey.technologyQuestions, surveyType.technologyQuestions, technologies);

                if (currentTabs.length)
                  return (
                    <Card key={page.pageId} className={classes.card}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="h5">{page.pageName}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            {surveyType.scoringType !== 'DHIShort' && (
                              <Button className={classes.button} color="primary" variant="contained" onClick={() => this.saveSurvey(survey)}>
                                {getResource(resources, survey.language, 'Label', 'Save')}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                        {isSaving && <LoadingOverlay />}
                        <CustomTabs
                          answers={answers}
                          auth={auth}
                          classes={{ button: classes.button }}
                          countriesList={countriesList}
                          delegates={delegates}
                          entity={entity}
                          //entityAmbulatories={entityAmbulatories}
                          //entityFreeStandingDataCenters={entityFreeStandingDataCenters}
                          //entityHomeHealths={entityHomeHealths}
                          //entityHospitals={entityHospitals}
                          //entityInHospitalDataCenters={entityInHospitalDataCenters}
                          //entitySubAcutes={entitySubAcutes}
                          //entityUntetheredAmbulatories={entityUntetheredAmbulatories}
                          handleRemove={this.handleRemoveDelegate}
                          handleResend={this.handleResendDelegate}
                          handleSend={this.handleSendDelegates}
                          inline={true}
                          isInternal={false}
                          handleNextPage={this.handleNextPage}
                          open={open}
                          page={page}
                          pageId={page.pageId}
                          pages={pagesWithProgress}
                          productsList={productsList}
                          questions={questions}
                          regionsList={regionsList}
                          saveSurvey={this.updateSurvey}
                          saveSurveyChanges={this.updateSurveyChanges}
                          showDelegates={hasPermission(CREATE_DELEGATES, permissions)}
                          survey={survey}
                          surveyType={surveyType}
                          tabs={currentTabs}
                          technologiesList={technologiesList}
                          technologyQuestions={survey.technologyQuestions}
                          vendorsList={vendorsList}
                          regions={regions}
                          resources={resources}
                          pageQuestionCount={pageQuestionCount}
                        />
                      </CardContent>
                    </Card>
                  );
                return '';
              })}
          </main>
          <DelegateModal
            auth={auth}
            delegates={delegates}
            handleClose={this.props.handleDialogClose}
            handleRemove={this.handleRemoveDelegate}
            handleResend={this.handleResendDelegate}
            handleSend={this.handleSendDelegates}
            open={openChildDialog}
            survey={survey}
            entity={entity}
            page={page}
            currentFilteredPageList={currentFilteredPageList}
            allPages={currentFilteredPageList}
            resources={resources}
            language={survey.language}
          />
          <Confirm
            title={getResource(resources, survey.language, 'Message', 'Email Resent to Delegate')}
            buttons="confirm-only"
            onClose={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.setState({ showConfirmDialog: false })}
            contentText={getResource(resources, survey.language, 'Message', 'An email has been re-sent to the delegate.')}
            open={this.state.showConfirmDialog}
            confirmText={getResource(resources, survey.language, 'Label', 'Confirm')}
          />
        </div>
      );
    }

    return (
      <Fragment>
        {!isEmpty(entity) && !isEmpty(surveyType) && !isEmpty(survey) && (
          <div>
            <div className={classes.cardsArea}>
              {pagesWithProgress
                .filter((page) => {
                  const isPermitted = hasDelegatePermission(delegates, auth.email, entity.entityID, page.pageId.toString());
                  if (!isPermitted && !isInternalUser) return false;
                  currentFilteredPageList.push(page);
                  return true;
                  //return (entity.haEntityTypeID === 6 || entity.haEntityTypeID === 8) && page.pageId !== 8 && page.pageId !== 13 && page.pageId !== 214 ? false : true;
                })
                .map((page, i) => (
                  <SurveyPageCard
                    delegates={this.getPageDelegates(page.pageId)}
                    handleToggleDelegate={() => this.setState({ open: !open, page })}
                    hasDelegatePermission={hasDelegatePermission(delegates, auth.email, entity.entityID, page.pageId.toString())}
                    key={i}
                    page={page}
                    permissions={permissions}
                    status={calculatePageStatus(`${page.pageId}`, entity, tabs, surveyType, survey, technologies)}
                    viewPage={this.viewPage}
                    survey={survey}
                    resources={resources}
                    auth={auth}
                  />
                ))}
            </div>
            <Button
              className={classes.footerHome}
              color="primary"
              variant={'contained'}
              onClick={() => {
                this.props.history.replace('/surveyLanding/' + this.props.survey.surveyGuid + '/home');
              }}
            >
              {getResource(resources, survey.language, 'Label', 'Home')}
            </Button>
          </div>
        )}
        <DelegateModal
          auth={auth}
          delegates={delegates}
          /*handleClose={() => this.setState({ openChildDialog: false })}*/
          handleClose={this.props.handleDialogClose}
          handleRemove={this.handleRemoveDelegate}
          handleResend={this.handleResendDelegate}
          handleSend={this.handleSendDelegates}
          open={openChildDialog}
          survey={survey}
          entity={entity}
          page={page}
          currentFilteredPageList={currentFilteredPageList}
          allPages={currentFilteredPageList}
          resources={resources}
          language={survey.language}
        />
        {this.state.isLoading && <LoadingOverlay />}
        <Confirm
          title={getResource(resources, survey.language, 'Message', 'Email Resent to Delegate')}
          buttons="confirm-only"
          onClose={() => this.setState({ showConfirmDialog: false })}
          onConfirm={() => this.setState({ showConfirmDialog: false })}
          contentText={getResource(resources, survey.language, 'Message', 'An email has been re-sent to the delegate.')}
          open={this.state.showConfirmDialog}
          confirmText={getResource(resources, survey.language, 'Label', 'Confirm')}
        />
      </Fragment>
    );
  }
}

const styles = ({ palette, spacing, theme }) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
  },
  appFrame: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  drawerPaper: {
    backgroundColor: palette.background.default,
    height: '100%',
    position: 'relative',
    width: 240,
  },
  content: {
    height: '100%',
    padding: spacing(1),
    width: '100%',
  },
  card: {
    margin: spacing(1),
  },
  button: {
    float: 'right',
    padding: '.375rem .75rem',
  },
  footerHome: {
    marginBottom: '10em',
    right: '16px',
    top: '14px',
    float: 'right',
    width: '87px',
  },
});

Entity.propTypes = {
  answers: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  countriesList: PropTypes.array.isRequired,
  delegates: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  permissions: PropTypes.array,
  productsList: PropTypes.array.isRequired,
  regionsList: PropTypes.array.isRequired,
  survey: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  technologies: PropTypes.array.isRequired,
  technologiesList: PropTypes.array.isRequired,
  vendorsList: PropTypes.array.isRequired,
  //entityHospitals: PropTypes.array,
  //entityAmbulatories: PropTypes.array,
  //entitySubAcutes: PropTypes.array,
  //entityHomeHealths: PropTypes.array,
  //entityFreeStandingDataCenters: PropTypes.array,
  //entityInHospitalDataCenters: PropTypes.array,
  //entityUntetheredAmbulatories: PropTypes.array,
  regions: PropTypes.array.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.entities.get('isLoading') || state.surveys.get('isLoading'),
    isSaving: state.surveys.get('isSaving'),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    addDelegate,
    getSurveyAMAMScore,
    getSurveyScore,
    removeDelegate,
    resendDelegate,
    saveSurvey,
    setDelegates,
    setSurvey,
    submitSurvey,
    handleToastMessage,
  })(Entity)
);
